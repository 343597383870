/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/dark.system.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lato-regular';
  src: url('./assets/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato-bold';
  src: url('./assets/fonts/Lato-Bold.ttf');
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

* {
  font-family: 'Lato-regular' !important;
}

b,
strong {
  font-family: 'Lato-bold';
}

h4 {
  font-size: 16px;
  line-height: 19.2px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h2 {
  font-size: 24px;
  line-height: 28.8px;
}

h1,
.heading-1 {
  font-size: 36px;
  line-height: 43.2px;
}

p,
h1,
h2,
h3,
h4 {
  margin: 16px 0px 10px 0px;
}

.main-auth-content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .main-auth-content--cont {
    flex: 1;
  }
}

button {
  width: 100%;
  margin: 16px 0px;
}

.button-white {
  --background: var(--ion-color-light);
  --border: 2px solid var(--ion-color-primary);
  color: #000000;
}

a {
  color: #000000;
  cursor: pointer;
}

ion-tab-bar {
  ion-tab-button {
    color: var(--ion-color-medium);
    text-align: center !important;
    min-width: 33.33% !important;
    border-bottom: 1px solid black;
    font-size: 14px;

    &.tab-selected {
      color: var(--ion-color-primary);
      font-weight: 800;
      border-bottom: 3px solid var(--ion-color-primary) !important;
    }
  }
}

ion-badge {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
}

.text-center {
  text-align: center;
}

.rounded-modal {
  --border-radius: 16px !important;
}

.onboarding-modal {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 660px;
  overflow-y: auto;
}

.declaration-modal {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 700px;
  overflow-y: auto;
}

.bank-account-modal {
  --height: auto;
  --max-height: 90%;
  --width: 60%;
  --max-width: 700px;
  overflow-y: auto;
}

.document-approval-size {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 800px;
  overflow-y: auto;
}

.discard-changes-modal {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 450px;
  overflow-y: auto;
}

.check-mint-modal {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 550px;
  overflow-y: auto;
}

.update-user-modal {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 550px;
  overflow-y: auto;
}

.terms-and-policies-modal {
  --height: auto;
  --max-height: 90%;
  --width: 50%;
  --max-width: 750px;
  overflow-y: auto;
}

.download-receipt-modal {
  --height: 100%;
  --max-height: 100%;
  --width: 50%;
  --max-width: 550px;
  overflow-y: auto;
}

.badge {
  height: 40px;
  width: 100%;
}

.rounded-b-24px {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.toast-item {
  --start: 'right-to-left' !important;
  margin-top: 100px;
  margin-left: -30px !important;
  font-weight: 400;
  font-size: 14px;
}

.toast-success {
  --ion-color-base: theme('colors.success');
  color: #000000;
}

.toast-error {
  --ion-color-base: theme('colors.error');
  color: #000000;
}

.btn:disabled {
  background-color: theme('colors.neutral');
}

.input,
.badge,
.checkbox,
.select,
.textarea {
  border-color: theme('colors.neutral');
}

.input:focus,
.input:focus-within,
.select:focus,
.textarea:focus {
  outline: none !important;
  border: 1px solid theme('colors.neutral');
  box-shadow: 0 0 5px theme('colors.neutral');
}

.color-error {
  color: var(--ion-color-danger);
}

.color-pass {
  color: var(--ion-color-success);
}

.modal-height-auto {
  --height: auto !important;
}

.modal-width-auto {
  --width: auto;
}

.modal-content {
  overflow-y: auto;
  max-height: calc(90vh - 20px);
  padding: 40px;
}

.btn-outline {
  border-width: 2px;
  border-style: solid;
}

.btn-outline:disabled {
  border-color: theme('colors.neutral');
}

.h-100vh {
  min-height: 100vh;
  max-height: 100vh;
}

/* MEDIA QUERIES */

@media (max-width: 768px) {
  .onboarding-modal,
  .declaration-modal,
  .bank-account-modal {
    --width: 90%;
    --max-width: none;
  }

  .modal-content {
    padding: 24px;
  }

  .discard-changes-modal {
    --width: 80%;
    --max-width: 80%;
    --min-width: 80%;
  }

  .download-receipt-modal {
    --width: 90%;
    --max-width: 90%;
  }
}
