// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  // Variables used by ionic but never defined
  --ion-background-color: #ffffff;
  --ion-text-color: #1d1d1b;

  /** primary **/
  --ion-color-primary: #000bd6;
  --ion-color-primary-rgb: 0, 11, 214;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000abc;
  --ion-color-primary-tint: #1a23da;

  /** secondary **/
  --ion-color-secondary: #00e281;
  --ion-color-secondary-rgb: 0, 226, 129;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #00c772;
  --ion-color-secondary-tint: #1ae58e;

  /** tertiary **/
  --ion-color-tertiary: #1d1d1b;
  --ion-color-tertiary-rgb: 29, 29, 27;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #1a1a18;
  --ion-color-tertiary-tint: #343432;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #f5334c;
  --ion-color-danger-rgb: 245, 51, 76;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #d82d43;
  --ion-color-danger-tint: #f6475e;

  /** dark **/
  --ion-color-dark: #132320;
  --ion-color-dark-rgb: 19, 19, 19;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #111111;
  --ion-color-dark-tint: #2b2b2b;

  /** medium **/
  --ion-color-medium: #686868;
  --ion-color-medium-rgb: 181, 181, 181;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #9f9f9f;
  --ion-color-medium-tint: #bcbcbc;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
}
